



























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
// @ is an alias to /src
import ViewLayoutComponent from '@/components/ViewLayoutComponent.vue';

@Component({
  components: {
    ViewLayoutComponent,
  },
})
export default class UserProfile extends Vue {
  public userName: string = '';
  public email: string = '';

  data() {
    return {
      userName: this.$store.state.user.user.username,
      email: this.$store.state.user.user.email,
    };
  }

  onChangePassword() {
    this.$buefy.snackbar.open('Feature not yet available');
  }
}
